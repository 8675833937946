@font-face {
  font-family: 'Roboto', sans-serif;
  src: local('Roboto'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans', sans-serif;
  src: local('Open Sans'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Add embedded-opentype for the font no sans-serif to fix "Failed to decode downloaded font url" */
@font-face {
  font-family: 'Siemreap';
  src: local('Siemreap'), url(./assets/fonts/Siemreap-Regular.ttf) format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'Khmer';
  src: local('Khmer'), url(./assets/fonts/Khmer-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Moulpali';
  src: local('Moulpali'), url(./assets/fonts/Moulpali-Regular.ttf) format('truetype');
}


@font-face {
  font-family: 'Hanuman';
  src: local('Hanuman'), url(./assets/fonts/Hanuman-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Metal';
  src: local('Metal'), url(./assets/fonts/Metal-Regular.ttf) format('truetype');
} */
